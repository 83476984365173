import Image from 'next/image';

const Socials = () => (
    <span>
        <a href="https://www.facebook.com/Flyer.be.the.original/" rel="noopener noreferrer" aria-label="Facebook" target="_blank">
            <Image
                src={`${process.env.NEXT_PUBLIC_CDN_IMAGES}layout/facebook.png`}
                alt="Facebook"
                width={26}
                height={25}
            />
        </a>
        <a href="https://be.linkedin.com/company/flyer-be-the-original" rel="noopener noreferrer" aria-label="Linkedin" target="_blank">
            <Image
                src={`${process.env.NEXT_PUBLIC_CDN_IMAGES}layout/linkedin.png`}
                alt="Linkedin"
                width={26}
                height={25}
            />
        </a>
        <a href="https://www.instagram.com/flyer.be_fr/" rel="noopener noreferrer" aria-label="Instagram" target="_blank">
            <Image
                src={`${process.env.NEXT_PUBLIC_CDN_IMAGES}layout/instagram.png`}
                alt="Instagram"
                width={26}
                height={25}
            />
        </a>
    </span>
);

export default Socials;
